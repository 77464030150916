import HeroSection from "./HeroSection";
import Importance from "./improtance";
import Message from "./mainMsg";
import InvestGuide from "./InvestGuide";
// import ProjectSlider from "./projectsSlider";
import { Helmet } from 'react-helmet';
import { useState, useEffect, lazy, Suspense } from 'react';
import { endload, startload } from '../../redux/slice/loader';
import { useDispatch } from 'react-redux';
import axios from "axios";

const ProjectSliderLazy = lazy(() => import('./projectsSlider'));
const Home = () => {
    const dispatch = useDispatch();
    const [data, setData] = useState({});

    const getContent = async () => {
        try {
            await axios.get("index.php?action=get_page_widgets&cnt_id=1").then((resp) => {
                setData(resp.data.data);
                dispatch(endload());
            })
        }
        catch (error) {
            console.log("get home page API error", error);

        }
    }
    useEffect(() => {
        dispatch(startload());
        getContent();
    }, [])

    return (
        <>
            < Helmet >
                <title>{data.cnt_title}</title>
                <meta name="description"
                    content={data.cnt_metades} />
                <meta property="og:description"
                    content={data.cnt_metades} />
                <meta name="keywords"
                    content={data.cnt_keywordsf} />
            </Helmet>
            <HeroSection heroText={data[1]} smallScall={data[0]} smalltime={data[2]} profit_timing={data[3]} />
            <Importance heading={data[4]} whyInvest={data[5]} howInvest={data[6]} whatInvest={data[7]} />
            <Message data={data[8]} />
            <InvestGuide data={data[9]} />
            <Suspense fallback={<div>Loading...</div>}>
                <ProjectSliderLazy aboutPage={"Active Investment Opportunities"} status={1} />
            </Suspense>

            <Suspense fallback={<div>Loading...</div>}>
                <ProjectSliderLazy aboutPage={"Past Investment Opportunities"} status={0} />
            </Suspense>
        </>
    )
}
export default Home;